
// Windows detect
$(() => {
    if (navigator.userAgent.indexOf('Win') !== -1) {
        $("body").addClass("win");
    }
});  

// Tooltip
$(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('.data-toggle-tooltip').tooltip();
    $('.data-toggle-tooltip').on('click', () => {
        $('.data-toggle-tooltip').tooltip('hide');
    });
});

// Popover & Toast
$(() => {
    $('[data-toggle="popover"]').popover({
        html: true
    });
    $('.toast').toast();
});

// File browser, custom-file
$(() => {
    bsCustomFileInput.init()
});

// Datetimepicker
$(() => {
    $('.datetimepicker').datetimepicker({
        format: 'YYYY-MM-DD H:mm',
        icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar-alt',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down'
        }
    });
    // Datepicker
    $('.datepicker').datetimepicker({
        format: 'YYYY-MM-DD',
        icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar-alt',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down'
        }
    });
    // Datepicker
    $('.timepicker').datetimepicker({
        format: 'H:mm',
        icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar-alt',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down'
        }
    });
});

// Dropdown-menu multilevel
$(() => {
    $('.dropdown-menu-multilevel').on('click', function(event){
        var events = $._data(document, 'events') || {};
        events = events.click || [];
        for(var i = 0; i < events.length; i++) {
            if(events[i].selector) {
                //Check if the clicked element matches the event selector
                if($(event.target).is(events[i].selector)) {
                    events[i].handler.call(event.target, event);
                }
                // Check if any of the clicked element parents matches the
                // delegated event selector (Emulating propagation)
                $(event.target).parents(events[i].selector).each(function(){
                    events[i].handler.call(this, event);
                });
            }
        }
        event.stopPropagation(); //Always stop propagation
    });
});
